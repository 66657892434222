import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollRestoration({ children }) {
  const loc = useLocation();
  useEffect(() => {
      window.scrollTo({
        top: 1,
        behavior: "smooth",
      });
  }, [loc]);

  return <>{children}</>;
}

export default ScrollRestoration;