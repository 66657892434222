import React from "react";
import {Helmet} from "react-helmet";


function Legal (props) {

    let content = {
        English: {
            title: "Legal information",
            message: "This website is owned and operated by DIVERKO, a one-person company under Belgian law, with company number is 0778.964.240, with registered office at 3010 Leuven (Belgium), Koetsweg 107"
        },
        Nederlands: {
            title: "Wettelijke informatie",
            message: "Deze website is eigendom van en wordt beheerd door DIVERKO, een eenmanszaak naar Belgisch recht, met ondernemingsnummer 0778.964.240, met maatschappelijke zetel te 3010 Leuven (België), Koetsweg 107."
        },
        Frans: {
            title: "Information légale",
            message: "Ce site web est la propriété de et est exploité par DIVERKO, une entreprise individuelle de droit Belge, avec numéro d’ entreprise 0778.964.240, et avec siège sociale à 3010 Louvain (Belgique), Koetsweg 107."
        }
    }

    props.language === "English" ? (content = content.English) : (props.language === "Frans" ? (content = content.Frans) : (content = content.Nederlands));

    return (
    <div className="page">
        <Helmet>
            <title>{content.title} | Diverko</title>
        </Helmet>
        <div className="py-32 h-full min-w-lg max-w-6xl m-auto px-2 300:px-8">
            <h1>{content.title}</h1>
            <p className="text pt-8">
                {content.message}
            </p>
            
        </div>
    </div>
    );
}

export default Legal;