import React from "react";
import {Helmet} from "react-helmet";
import lost from '../img/lost.jpg';


function Error (props) {

    let content = {
        English: {
            oops: "Oops",
            lost: ", seems like you got lost.",
            exist: "This page does not exist."
        },
        Nederlands: {
            oops: "Oeps",
            lost: ", je hebt ergens de verkeerde afslag genomen.",
            exist: "Deze pagina bestaat niet."
        },
        Frans: {
            oops: "Oops",
            lost: "Vous vous êtes trompé de chemin quelque part.",
            exist: "Cette page n'existe pas."
        }
    }

    props.language === "English" ? (content = content.English) : (props.language === "Frans" ? (content = content.Frans) : (content = content.Nederlands));

    return (
    <div className="page">
        <Helmet>
            <title>Error | Diverko</title>
        </Helmet>
        <div className="py-32 h-full min-w-lg max-w-6xl m-auto">
            <div className="flex flex-col lg:flex-row p-8 justify-between items-center lg:items-start">
                <div className="p-8 flex flex-col items-center lg:items-start justify-start ">
                    <h1><span className="text-blue-500">{content.oops}</span>{content.lost}</h1>
                    <p className="text">{content.exist}</p>
                </div>
                <img src={lost} alt="lost" className="hidden 210:block w-32 h-32 300:w-60 300:h-60 lg:w-80 lg:h-80 rounded-full"/>
            </div>
            
        </div>
    </div>
    );
}

export default Error;