import React from "react";
import logoIveco from "../img/logo-iveco-flat.png";

function PoweredBy(props) {

    let content = {
        English: {
            power: "Powered by us: "
        },
        Nederlands: {
            power: "Gedreven door ons: "
        },
        Frans: {
            power: "Guidées par nous: "
        }
    }
    
    props.language === "English" ? (content = content.English) : (props.language === "Frans" ? (content = content.Frans) : (content = content.Nederlands));

    return (
        <div className="py-32 ">
            <div className="w-full bg-black dark:bg-slate-200 bg-opacity-10 dark:bg-opacity-10">
                <div className="mx-auto flex max-w-6xl flex-col">
                    <h1 className="text py-8 text-center">{content.power}</h1>
                    <div className="max-w-6xl flex flex-col lg:flex-row justify-center items-center px-2 300:px-8">
                        <a href="https://www.iveco.com/Pages/Iveco-brands.html" target="_blank" rel="noreferrer noopener" className="outline-none"><img src={logoIveco} alt="logo-iveco" className="logo"/></a>
                        {/* <img src={logoIveco} alt="logo-iveco" className="logo"/>
                        <img src={logoIveco} alt="logo-iveco" className="logo"/> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PoweredBy;