import React from "react";
import {Helmet} from "react-helmet";
import ContactComp from "../component/ContactComp";
import MissionsComp from "../component/MissionsComp";

function Missions (props) {

    let content = {
        English: {
            title: "Missions",
            race: "Your race.",
            mission: "Our Mission."
        },
        Nederlands: {
            title: "Missies",
            race: "Jouw race.",
            mission: "Onze Missie."
        },
        Frans: {
            title: "Missions",
            race: "Votre course.",
            mission: "Notre Mission."
        }
    }

    props.language === "English" ? (content = content.English) : (props.language === "Frans" ? (content = content.Frans) : (content = content.Nederlands));

    return (
        <div className="page">
            <Helmet>
                <title>{content.title} | Diverko</title>
            </Helmet>
                <div className="w-full bluegradient my-32 py-16  px-2 300:px-8 flex items-center justify-center">
                    <h1 className="page__title">{content.race}<span className="text-slate-100"> {content.mission}</span></h1>
                </div>
            <MissionsComp language={props.language}/>
            <ContactComp language={props.language}/>
        </div>
    );
}

export default Missions;