import React from "react";

function SmallBanner(props) {
    return (
        <div className="py-8 w-full flex justify-center">
            <div className="-skew-y-12 bg-blue-500 px-2 300:px-8 py-4">
                 <h1 className="dark:text-black ">{props.message}</h1>
            </div>
         </div>
    );
}

export default SmallBanner;