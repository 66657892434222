import React from "react";
import {Helmet} from "react-helmet";
import ContactComp from "../component/ContactComp";
import Mission from "../component/Mission";

function Training (props) {

    let content = {
        English: {
            explanation: "Does your team need some training to reach their potential? Are you in need of a better sales strategy?",
            one: "In group",
            two: "Intern or extern",
            three: "On the job (one-on-one)"
        },
        Nederlands: {
            explanation: "Heeft jouw team nood aan training en begeleiding? Heb jij nood aan een betere verkoops strategie?",
            one: "In groep",
            two: "Intern of extern",
            three: "on the job (een-op-een)"
        },
        Frans: {
            explanation: "Votre équipe a-t-elle besoin de formation et de coaching ? Avez-vous besoin d'une meilleure stratégie de vente ?",
            one: "En groupe",
            two: "En interne ou en externe",
            three: "pendant le boulot (un sur un)"
        }
    }

    props.language === "English" ? (content = content.English) : (props.language === "Frans" ? (content = content.Frans) : (content = content.Nederlands));

    return (
        <div className="page">
            <Helmet>
                <title>Training | Diverko</title>
            </Helmet>
            <div className="w-full bluegradient my-32 py-16  px-2 300:px-8 flex items-center justify-center">
                    <h1 className="page__title">Training </h1>
                </div>
            <div className="m-auto max-w-6xl grid-rows-1 justify-center py-32">
                    <Mission title="Training" explanation={content.explanation} one={content.one} two={content.two} three={content.three} />
            </div>
            <ContactComp language={props.language}/>
        </div>
    );
}

export default Training;