import React from "react";
import {Helmet} from "react-helmet";
import BikeComp from "../component/BikeComp";
import ContactComp from "../component/ContactComp";
import MissionsComp from "../component/MissionsComp";
import PoweredBy from "../component/poweredBy";
import SmallBanner from "../component/SmallBanner";
import Race from "../img/race1.jpg"

function Home (props) {

    let content = {
        English: {
            welcome: "Welcome!",
            banner: "Are you looking for a positive",
            banner2: "in your sales team?",
            intro: "At",
            intro2: "we work with you in a logic, ergonomic, simple and informing way. And always with a passion.",
            missions: "Our Missions.",
            contact: "Contact us."
        },
        Nederlands: {
            welcome: "Welkom!",
            banner: "Ben jij op zoek naar een positieve",
            banner2: "in jouw verkoopsteam?",
            intro: "Bij",
            intro2: "werken we met jou samen op een logische, ergonomischer, simpele and informatieve manier. En steeds met passie.",
            missions: "Onze Missies.",
            contact: "Contacteer ons."
        },
        Frans: {
            welcome: "Bienvenue!",
            banner: "Cherchez-vous un",
            banner2: "positif dans votre équipe de vente?",
            intro: "Chez",
            intro2: "nous travaillons avec vous de manière logique, ergonomique, simple et informative. Et toujours avec passion.",
            missions: "Nos Missions.",
            contact: "Contactez-nous."
        }
    }

    props.language === "English" ? (content = content.English) : (props.language === "Frans" ? (content = content.Frans) : (content = content.Nederlands));

    return (
        <div className="page">
            <Helmet>
                <title>Home | Diverko</title>
            </Helmet>
            <div className="py-32">
                    <div className="px-2 300:px-8 max-w-6xl m-auto py-8 flex flex-col animate-slideDown">
                        <SmallBanner message={content.welcome} />
                        <h1 className=" first-letter:text-8xl text-center lg:text-left">{content.banner} <span className="text-teal-600 font-extrabold">DRIVE</span> {content.banner2}</h1>
                    </div>
            </div>
            <div className="mx-auto max-w-6xl space-y-8 lg:space-y-0 grid  md:grid-cols-2 items-center ">
                <div className="px-2 300:px-8 list-disc text flex flex-col justify-center">
                    <p className="">{content.intro} <span className="font-bold text-2xl text-blue-500">Diverko</span> {content.intro2}</p>
                </div>
                <div className="flex justify-center">
                    <img src={Race} alt="Dirk" className="w-32 h-32 210:block 210:w-48 210:h-48 300:w-60 300:h-60 lg:w-80 lg:h-80 rounded-full overflow-hidden object-cover "/>
                </div>
            </div>
            <PoweredBy language={props.language}/>
            <SmallBanner message={content.missions}/>
            <MissionsComp language={props.language}/>
            <BikeComp language={props.language}/>
            <SmallBanner message={content.contact}/>
            <ContactComp language={props.language}/>  
        </div>
    )
}

export default Home;