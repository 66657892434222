import React from "react";
import { NavLink } from "react-router-dom";
import logo from '../img/logo.png';
function Footer(props) {

    let content = {
        English: {
            privacy: "Privacy Policy",
            GTC: "General Terms and Conditions",
            cookies: "Cookies Policy",
            legal: "Legal information"
        },
        Nederlands: {
            privacy: "Privacy Beleid",
            GTC: "Algemene Gebruiksvoorwaarden",
            cookies: "Cookies Beleid",
            legal: "Wettelijke informatie"
        },
        Frans: {
            privacy: "Politique de Confidentialité",
            GTC: "Conditions Général",
            cookies: "Politique sur les cookies",
            legal: "Information légale"
        }
    }

    props.language === "English" ? (content = content.English) : (props.language === "Frans" ? (content = content.Frans) : (content = content.Nederlands));

    return (
        <div className="row-start-4 w-full mx-auto p-4 border-t border-slate-200 dark:border-stone-900">
            <div className="max-w-6xl m-auto flex flex-col sm:flex-row items-center justify-between px-2 300:px-8 gap-2 300:gap-4">
                <NavLink className={({isActive})=> (isActive ? '' : 'linkfooter')}
                to="/">
                    <img src={logo} alt='logo' className='h-12 place-content-center '/>
                </NavLink>
                <ul className="flex flex-wrap items-center justify-center gap-2 300:gap-4 ">
                    <NavLink className="border-0 focus:outline-none"
                    to="/general-terms-and-conditions" >
                        <li className="linkfooter">
                            {content.GTC}
                        </li>
                    </NavLink>
                    <NavLink className="border-0 focus:outline-none"
                     to="/cookies-policy" >
                        <li className="linkfooter">
                            {content.cookies}
                        </li>
                    </NavLink>
                    <NavLink className="border-0 focus:outline-none"
                    to="/privacy-policy" >
                        <li className="linkfooter">
                            {content.privacy}
                        </li>
                    </NavLink>
                    <NavLink className="border-0 focus:outline-none"
                    to="/legal-information" >
                        <li className="linkfooter">
                            {content.legal}
                        </li>
                    </NavLink>
                </ul>
            </div>  
        </div>
    );
}

export default Footer;