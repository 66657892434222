import React from "react";
import {Helmet} from "react-helmet";


function Cookies (props) {

    let content = {
        English: {
            title: "Cookies Policy",
            cookie: "Currently we are ",
            not: "not ",
            cookie2: "collecting any Data with cookies. To us you are a person and not just a number.",
            cookie3: "Currently we do not have any utility that requires the use of cookies. If ever this would change in the future we will respect the legal requirements surrounding cookies and we will inform you on visiting our website."
        },
        Nederlands: {
            title: "Cookies Beleid",
            cookie: "Momenteel verzamelen wij ",
            not: "geen ",
            cookie2: " van uw gegevens met cookies. U bent een persoon en geen nummer.",
            cookie3: "Momenteel hebben wij geen nood voor het gebruik van cookies. Indien dit zou veranderen zullen wij ons houden aan de legale vereisten en zullen wij u hierover informeren wanneer u de website bezoekt."
        },
        Frans: {
            title: "Politique sur les cookies",
            cookie: "Actuellement, nous ne collectons ",
            not: "aucune ",
            cookie2: " de vos données à l'aide de cookies. Vous êtes une personne et non un numéro.",
            cookie3: "Nous n'avons actuellement aucune obligation d'utiliser des cookies. Si cela devait changer, nous nous conformerions aux exigences légales et nous vous en informerions lors de votre visite sur le site web."
        }
    }


    props.language === "English" ? (content = content.English) : (props.language === "Frans" ? (content = content.Frans) : (content = content.Nederlands));

    return (
    <div className="page">
        <Helmet>
            <title>{content.title} | Diverko</title>
        </Helmet>
        <div className="py-32 h-full min-w-lg max-w-6xl m-auto px-2 300:px-8">
            <h1>{content.title}</h1>
            <p className="text pt-8">
                {content.cookie}<span className="font-bold text-xl">{content.not}</span>{content.cookie2}<br />
                <br />
                {content.cookie3}
            </p>
            
        </div>
    </div>
    );
}

export default Cookies;