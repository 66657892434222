import React from "react";
import {Helmet} from "react-helmet";
import PoweredBy from "../component/poweredBy";
import Tag from "../component/Tag";
import Dirk from "../img/dirk.jpg";

function About (props) {

    let content = {
        English: {
            title: "About us",
            banner: "plus years of experience combined with a passion",
            h2one: "Are you looking for a ",
            h2two: "Enough of long talks and little action? ",
            h2three: "Get ready for our go-get attitude!",
            dirk: "My name is Dirk Vandeput",
            presentation: "I am the driving force behind Diverko and the driving force behind your next project. ",
            presentation2: "After more than 20 years of experience in sales I would like to share that experience and knowledge with you and your customers.",
            tag1: "Worldwide network",
            tag2: "Active in BeNeLux",
            tag3: "Team management",
            tag4: "Budget control",
            tag5: "Customer satisfaction",
            tag6: "Account management",
            tag7: "Negotiation",
            tag8: "Problem solving",
            tag9: "Reqruitment",
            tag10: "Sales development",
            tag11: "Sales coach",
            tag12: "Learning by example",
            tag13: "Passion for sales and motorcycles"
        },
        Nederlands: {
            title: "Over ons",
            banner: "jaar ervaring gecombineerd met een passie",
            h2one: "Ben jij opzoek naar een ",
            h2two: "Geen bla-bla-bla! of ellenlange presentaties: simpel en effectief!",
            h2three: "Onze aanpak is een die de handen uit mouwen steekt!",
            dirk: "Mijn naam is Dirk Vandeput",
            presentation: "Ik ben de drijvende kracht achter Diverko en de drijvende kracht bij en voor jouw volgend project.",
            presentation2: "Na meer dan 20 jaar ervaring in sales ben ik klaar om die ervaring en kennis te delen en in te zetten met en voor jouw volgende klant.",
            tag1: "Wereldwijd netwerk",
            tag2: "Actief in BeNeLux",
            tag3: "Team management",
            tag4: "Budget controle",
            tag5: "Klant tevredenheid",
            tag6: "Account management",
            tag7: "Onderhandelen",
            tag8: "Probleem oplossend",
            tag9: "Aanwerving",
            tag10: "Verkoopsontwikkeling",
            tag11: "Verkoopscoach",
            tag12: "Learning by example",
            tag13: "Passion for sales and motorcycles"
        },
        Frans: {
            title: "À propos de nous",
            banner: "années d'expérience combinées à une passion",
            h2one: "Vous cherchez un ",
            h2two: "Pas des conversations inutiles ! ni de présentations interminables : simple et efficace!",
            h2three: "Notre approche est une approche pratique!",
            dirk: "Je m’appelle Dirk Vandeput",
            presentation: "Je suis la force motrice derrière Diverko et la force motrice pour votre prochain projet.",
            presentation2: "Avec plus de 20 ans d'expérience dans la vente, je suis prêt à partager et à déployer cette expérience et ces connaissances chez et pour votre prochain client.",
            tag1: "Réseau mondial",
            tag2: "Actif au BeNeLux",
            tag3: "Gestion d’équipe",
            tag4: "Contrôle budgétaire",
            tag5: "Satisfaction clientèle",
            tag6: "Gestion des comptes",
            tag7: "Négociation",
            tag8: "Résolution de problèmes",
            tag9: "Recruter",
            tag10: "Développement des ventes",
            tag11: "Coaching Commercial",
            tag12: "Learning by example",
            tag13: "Passion for pour la vente et les motos"
        }
    }

    props.language === "English" ? (content = content.English) : (props.language === "Frans" ? (content = content.Frans) : (content = content.Nederlands));

    return (
        <div className="page">
            <Helmet>
                <title>{content.title} | Diverko</title>
            </Helmet>
            <div className="py-32 w-full m-auto">
                <div className="flex flex-col justify-center items-center lg:items-start">
                    <div className="bluegradient w-full flex justify-center">
                        <div className="p-8 max-w-6xl flex flex-col items-start justify-start ">
                            <h1 className=" dark:text-black text-2xl md:text-6xl">
                                <span className="text-slate-200">20</span> {content.banner}
                            </h1>
                            <h2 className="pb-3 dark:text-black"> 
                                {content.h2one} <span className="text-slate-200">personal touch</span>? <br/>
                                {content.h2two} <br/>
                                {content.h2three}
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-32 m-auto flex justify-center items-center max-w-6xl flex-col lg:flex-row gap-8 lg:gap-16">
                <img src={Dirk} alt="Dirk" className="w-32 h-32 210:block 210:w-48 210:h-48 300:w-60 300:h-60 lg:w-80 lg:h-80 rounded-full"/>
                <div className="p-8 flex flex-col lg:items-start justify-center ">
                    <h1 className="text-start">{content.dirk}</h1>
                    <p className="text ">
                        {content.presentation} <br/>
                        {content.presentation2}
                    </p>
                    <div className="flex flex-wrap py-3">
                        <Tag tag={content.tag1}/>
                        <Tag tag={content.tag2}/>
                        <Tag tag={content.tag3}/>
                        <Tag tag={content.tag4}/>
                        <Tag tag={content.tag5}/>
                        <Tag tag={content.tag6}/>
                        <Tag tag={content.tag7}/>
                        <Tag tag={content.tag8}/>
                        <Tag tag={content.tag9}/>
                        <Tag tag={content.tag10}/>
                        <Tag tag={content.tag11}/>
                        <Tag tag={content.tag12}/>
                        <Tag tag={content.tag13}/>
                    </div>
                </div>             
            </div>
            <PoweredBy language={props.language}/>    
        </div>
    );
}

export default About;