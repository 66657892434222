import React from "react";
import {NavLink } from "react-router-dom";
import logo from '../img/logo.png';
import NavLinkComp from "./NavLinkComp";
import { FaBars, FaPlus, FaMoon} from 'react-icons/fa';
import { useState } from "react";

function NavBar(props) {

    const [show, setShow] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);

    let content = {
        English: {
            missions: "Missions",
            about: "About us"
        },
        Nederlands: {
            missions: "Missies",
            about: "Over ons" 
        },
        Frans: {
            missions: "Missions",
            about: "A propos de nous"
        }
    }
    
    props.language === "English" ? (content = content.English) : (props.language === "Frans" ? (content = content.Frans) : (content = content.Nederlands));

    return (
        <div className="fixed top-0 z-20 w-full bg-teal-50 dark:bg-black">
            <div className="flex justify-end text-black dark:text-slate-100 px-2 300:px-8 mt-2 gap-2">
                <button className="outline-none" onClick={() => props.handleSetDarkMode(!props.darkMode)}>
                    <FaMoon />
                </button>
                <button className="outline-none" value="Nederlands" onClick={e => props.handleSetLanguage(e.target.value)}>NL</button>
                <button className="outline-none" value="Frans" onClick={e => props.handleSetLanguage(e.target.value)}>FR</button>
                <button className="outline-none" value="English" onClick={e => props.handleSetLanguage(e.target.value)}>EN</button>
            </div>
            <nav className="">
                <ul className="w-full mx-auto hidden 800:flex flex-row items-center justify-between p-4">
                    <div className="right-0 items-center">
                        <NavLink className={({isActive})=> (isActive ? 'active' : 'link')}
                        to="/">
                            <li className="p-3 font-semibold text-lg text-left flex flex-row left-0 items-center">
                                <img src={logo} alt='logo' className='h-12 backdrop-blur-sm place-content-center'/>
                                <p className="p-3 text-3xl text-black dark:text-slate-100">Diver<span className="text-blue-500">ko</span></p>
                            </li>
                        </NavLink>
                    </div>
                    <div className="flex flex-row">
                        <div onMouseEnter={() => setShowDropdown(true)} className="relative" onMouseLeave={() => setShowDropdown(false)}>
                            <NavLinkComp link="/missions" name={content.missions}/>
                            {showDropdown && 
                                <div className="bg-teal-50 dark:bg-black border-2 border-blue-500 shadow-2xl absolute flex flex-col text">
                                    <NavLinkComp link="/consultancy" name="Consultancy"/>
                                    <NavLinkComp link="/training" name="Training"/>
                                </div>}
                        </div>
                        
                        <NavLinkComp link="/about" name={content.about}/>
                        <NavLinkComp link="/contact" name="Contact"/>
                        <NavLink className={({isActive})=> (isActive ? 'activebutton' : 'linkbutton')} 
                        to="/motorcycles">
                            <div className="pl-3">
                                <li className="my-3 border-2 rounded-3xl border-blue-500 hover:bluegradient hover:border-y-transparent hover:border-l-teal-600 hover:border-r-blue-600 font-semibold text-lg text-left hover:text-black flex flex-row left-0 items-center ">
                                    <p className="px-3 ">Let's talk about motorcycles</p>
                                </li>
                            </div>
                        </NavLink>
                    </div>
                </ul>  
                <ul className="w-full mx-auto h-full flex 800:hidden flex-col p-4">
                    <div className="flex flex-row items-center 210:justify-between">
                        <NavLink className={({isActive})=> (isActive ? 'active' : 'link')}
                        to="/">
                            <li className="p-3 font-semibold text-lg text-left hidden 210:flex 210:flex-row left-0 items-center" onClick={() => setShow(false)}>
                                <img src={logo} alt='logo' className='h-12 backdrop-blur-sm place-content-center'/>
                                <p className="hidden 300:flex p-3 text-2xl text-black dark:text-slate-100">Diver<span className="text-blue-500">ko</span></p>
                            </li>
                        </NavLink>
                        <li className="link 210:right-0">
                            <button className="h-12 transition-all" type="button" onClick={() => setShow(!show)}>
                                {show || <FaBars size={25} />}
                                {show && <FaPlus size={25} className="rotate-45"/>}
                            </button>
                        </li>
                    </div>
                    {show && 
                    <div className=" 800:hidden flex flex-col">
                        <button type="button" onClick={() => setShow(!show)}>
                        <NavLinkComp link="/" name="Home" css="210:hidden"/>
                        <NavLinkComp link="/missions" name={content.missions}/>
                        <div className="pl-6 ">
                            <NavLinkComp link="/consultancy" name="Consultancy"/>
                            <NavLinkComp link="/training" name="Training"/>
                        </div>
                        <NavLinkComp link="/about" name={content.about}/>
                        <NavLinkComp link="/contact" name="Contact"/>
                        <NavLinkComp link="/motorcycles" name="Let's talk about motorcycles"/>
                        </button>
                    </div>}
                </ul>      
            </nav>
            
        </div>
    );
}

export default NavBar;