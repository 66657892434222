import React, { useRef, useState } from "react";
import emailjs from '@emailjs/browser';

function ContactForm(props) {

    const form = useRef();
    const [result,showResult] = useState(false)
    const Result =() => {
        return (
            <p className="formGroup">{content.succes}</p>
        );
    }

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_Diverko', 'template_diverko', form.current, 'oQGJoMgbMuBQ6Kd2c')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
        e.target.reset();
        showResult(true);
      };

      let content = {
        English: {
            name: "First & last name",
            message: "Message",
            send: "Send",
            succes: "Message sent, we will contact you soon."
        },
        Nederlands: {
            name: "Voor- & achternaam",
            message: "Mededeling",
            send: "Verzend",
            succes: "Bericht verzonden, wij nemen snel contact met u op."
        },
        Frans: {
            name: "Pré-nom et Nom",
            message: "Note",
            send: "Envoyez",
            succes: "Message envoyé, nous vous contacterons bientôt."
        }
    }

    props.language === "English" ? (content = content.English) : (props.language === "Frans" ? (content = content.Frans) : (content = content.Nederlands));

    return (
        <form ref={form} onSubmit={sendEmail} className="w-full flex flex-col gap-4 pt-8">
            <div className="formGroup">
                <input type="text" id="Name" name="user_name" placeholder={content.name} className="input" required/><br/>
            </div>
            <div className="formGroup">
                <input type="email" id="Email" name="user_email" placeholder="E-mail" className="input" required/><br/>
            </div>
            <div className="formGroup">
                <textarea type="text" id="Message" name="message" placeholder={content.message} maxLength="5000" className="h-27 input resize-y pb-8 max-h-80" required/><br/>
            </div>
            <div className="font-medium text-lg italic space-y-0.5 w-full text-green-400">{result ? <Result/> : null}</div>
            <button type="submit" className="button max-w-fit my-8" value="Send">{content.send}</button>
        </form>
    );
}

export default ContactForm;