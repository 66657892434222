import React from "react";

function Mission (props) {
    return (
        <div className="max-w-6xl px-2 300:px-4 dark:shadow-stone-800 bluegradient text-slate-100 mx-2 300:mx-4 my-8 grid-rows-3 text-center justify-center">
            <div className="xl:row-span-2 flex flex-col items-center justify-center ">
                <h1 className="  p-4 text-center my-4 mb-8">{props.title}</h1>
                <p className="text pb-8 max-w-sm text-center">{props.explanation}</p>
            </div>
            <ul className="marker:text-slate-100 list-disc flex flex-col items-center p-8 border-t-2 border-blue-900 dark:text-slate-100">
                <li>{props.one}</li>
                <li>{props.two}</li>
                <li>{props.three}</li>
            </ul>
            
        </div>
    );
}

export default Mission;