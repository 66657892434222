import React from "react";
import { NavLink } from "react-router-dom";
import consultancy from "../img/engine2.jpg";
import training from "../img/training.jpg";

function MissionsComp(props) {

    let content = {
        English: {
            consultancy: "We offer a wide range of consultancy services. Every project gets its own personal touch.",
            training: "Nothing better than to share my experiences and help others grow."
        },
        Nederlands: {
            consultancy: "Wij bieden een breed assortiment van services aan. Elk project op maat gemaakt.",
            training: "Niets beter dan mijn ervaring te delen en anderen te helpen groeien."
        },
        Frans: {
            consultancy: "Nous offrons une large gamme de services. Chaque projet est conçu sur mesure.",
            training: "Rien de mieux que de partager mon expérience et d'aider les autres à se développer."
        }
    }
    
    props.language === "English" ? (content = content.English) : (props.language === "Frans" ? (content = content.Frans) : (content = content.Nederlands));

    return (
        <div className="m-auto max-w-[90%] md:max-w-6xl flex flex-col lg:flex-row justify-center gap-8 py-32">
            <div className="flex-1 mx-2 sm:mx-4 shadow-md">
                <NavLink to="/consultancy" className="focus:outline-none">
                    <div className="hover:bluegradient hover:text-slate-100 text shadow-md dark:shadow-stone-800">
                        <div className="row-span-1 px-2 300:px-4 py-8 ">
                            <h1 className="">Consultancy</h1>
                            <p>{content.consultancy}</p>
                        </div>
                        <div className="row-span-2">
                            <img src={consultancy} alt="consultancy" className=" object-cover"/>
                        </div>
                    </div>
                </NavLink>
            </div>
            <div className="flex-1 mx-2 sm:mx-4 shadow-md">
                <NavLink to="/training" className="focus:outline-none">
                    <div className="hover:bluegradient text hover:text-slate-100 shadow-md dark:shadow-stone-800">
                        <div className="row-span-1 px-2 300:px-4 py-8">
                            <h1  className="">Training</h1>
                            <p>{content.training}</p>
                        </div>
                        <div className="row-span-2">
                            <img src={training} alt="training" className="object-cover"/>
                        </div>
                    </div>
                </NavLink>
            </div>
        </div>
    );
}

export default MissionsComp;