import React from "react";
import {Helmet} from "react-helmet";
import ContactComp from "../component/ContactComp";
import ReviewGallery from "../component/reviewGallery";

function Motorcycles (props) {

    let content = {
        English: {
            banner: "Do you want to get to know your passion on a technical level (with a lot of fun and coffee!) ?",
            underbanner: "For those motorcycle fanatics who would love to “manage” their own machine under supervision and guidance of an expert.",
            ul: "After a brief conversation we will pinpoint your needs:",
            li1: "You want to learn the basics? What is important for safety? Oil? What do they do in a workshop? You can do that yourself!",
            li2: "You are looking for big repairs/restorations (maybe over multiple days?) and of course you want to do that yourself (with guidance)!",
            banner2: "We offer you a safe environment and the guidance for you to work on your motorcycle yourself!",
            ul2: "And this comes with many advantages",
            li12: "You will drive safer.",
            li22: "You will know your motorcycle better.",
            li32: "You will know when to do the small check-ups (tirepressure is important)",
            not: "What can't we do?",
            li42: "Make you the next Valentino Rossi!... ;-)"
        },
        Nederlands: {
            banner: "Wil jij je passie technisch (en met veel fun en koffie!) beter leren kennen?",
            underbanner: "Voor die motormaniakken die zo graag hun eigen machine eens “onder handen” willen nemen onder begeleiding van een expert.",
            ul: "Na een kort gesprek spreken we af waar jij naar op zoek bent:",
            li1: "Wil je de basisbegrippen kennen? Wat is belangrijk voor de veiligheid? Olie? Wat doet een werkplaats nu? Kan ik dat zelf?",
            li2: "Ga je dadelijk om een grote herstelling uit te voeren (over meerdere dagen misschien?) en je wil absoluut dat zelf doen (onder begeleiding)?",
            banner2: "Wij bieden jou in een veilige omgeving en onder begeleiding de mogelijkheid om zelf aan jouw machine te sleutelen!",
            ul2: "En dit komt met veel voordelen.",
            li12: "Je rijden wordt veiliger ",
            li22: "Je kent je machine beter",
            li32: "Je wordt bewuster van de kleine check-ups (bandenspanning is WEL belangrijk)",
            not: "Wat kunnen we niet?",
            li42: "Van jou een Valentino Rossi maken!... ;-)"
        },
        Frans: {
            banner: "Voulez-vous apprendre à mieux connaître votre passion sur le plan technique (et avec beaucoup de plaisir et de café !)?",
            underbanner: "Pour les fous de moto qui ont envie de prendre en main leur propre machine pour une fois sous la direction d'un expert.",
            ul: "Après une brève discussion, nous nous mettrons d'accord sur ce que vous recherchez:",
            li1: "Voulez-vous connaître les principes de base ? Qu'est-ce qui est important pour la sécurité ? L'huile ? Que fait un atelier ? Est-ce que je peux le faire moi-même?",
            li2: "Vous allez prochainement effectuer une réparation importante (sur plusieurs jours peut-être ?) et vous voulez absolument le faire vous-même (sous surveillance)?",
            banner2: "Nous vous offrons la possibilité de bricoler vous-même votre machine dans un environnement sûr et sous surveillance!",
            ul2: "Et cela présente de nombreux avantages.",
            li12: "Votre conduite sera plus sûre ",
            li22: "Vous connaissez mieux votre machine",
            li32: "Vous devenez plus conscient des petits contrôles (la pression des pneus est TRÈS importante)",
            not: "Qu'est-ce qu'on ne peut pas fairet?",
            li42: "Faire de vous un Valentino Rossi !.... ;-)"
        }
    }

    props.language === "English" ? (content = content.English) : (props.language === "Frans" ? (content = content.Frans) : (content = content.Nederlands));

    return (
        <div className="page">
            <Helmet>
                <title>Motorcycles | Diverko</title>
            </Helmet>
            <div className="bluegradient px-2 300:px-8 my-32 py-8 flex justify-center">
                <div className="max-w-6xl flex flex-col">
                    <h1 className="text-2xl md:text-6xl">{content.banner}</h1>
                    <h3>{content.underbanner}</h3>
                </div>
            </div>
            <div className="my-32 px-2 300:px-8 flex justify-center items-center">
                <div className="max-w-6xl flex flex-col">
                    <h3 className="text-blue-500 dark:text-blue-500">{content.ul}</h3>
                    <ul className="list-disc dark:text-slate-100 text leading-loose ml-8">
                        <li>{content.li1}</li>
                        <li>{content.li2}</li>
                    </ul>
                </div>
            </div>
            <div className="my-32 px-2 300:px-8 flex justify-center items-center">
                <div className="max-w-6xl flex flex-col lg:flex-row gap-8 lg:gap-16">
                    <h2 className="bg-teal-600 dark:text-black p-2 300:p-8">{content.banner2}</h2>
                    <div className="flex flex-col gap-4 py-2 300:py-8">
                        <h3 className="text-blue-500 dark:text-blue-500">{content.ul2}</h3>
                        <ul className="text list-disc ml-8">
                            <li>{content.li12}</li>
                            <li>{content.li22}</li>
                            <li>{content.li32}</li>
                        </ul>
                        <h3 className="text-blue-500 dark:text-blue-500">{content.not}</h3>
                        <ul className="text list-disc ml-8">
                            <li>{content.li42}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <ReviewGallery />
            <ContactComp language={props.language}/>
        </div>
    );
}

export default Motorcycles;