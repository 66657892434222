import './index.css';
import React, { useState } from "react";
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Error from './pages/Error';
import About from './pages/About';
import NavBar from './component/NavBar';
import Footer from './component/Footer';
import Missions from './pages/Missions';
import Contact from './pages/Contact';
import engine from './img/oldbike.jpg';
import Motorcycles from './pages/Motorcycles';
import Consultancy from './pages/Consultancy';
import Training from './pages/Training';
import Cookies from './pages/Cookies';
import Legal from './pages/Legal';
import Privacy from './pages/Privacy';
import GTC from './pages/GTC'


function App() {

  let languageStoredInLocalStorage = localStorage.getItem("language");
  let darkModeStoredInLocalStorage = localStorage.getItem("darkMode")
  let [language, setLanguage] = useState(
    languageStoredInLocalStorage ? languageStoredInLocalStorage : "English"
  );

  let [darkMode, setDarkMode] = useState(
    darkModeStoredInLocalStorage ? darkModeStoredInLocalStorage : true
  );

  return (
    <div className={darkMode ? "dark" : ""}>
      <div className='page'>
        <div className=' grid grid-rows-layout'>
          <NavBar darkMode={darkMode} language={language}
          handleSetDarkMode={darkmode => {
            setDarkMode(darkmode);
            localStorage.setItem("darkMode", darkMode);
          }}
          handleSetLanguage={language => {
            setLanguage(language);
            localStorage.setItem("language", language);
          }}/>
          <div className='h-32'/>
          <img src={engine} alt="banner" className=' object-cover w-full h-64'/>
          <Routes>
            <Route path='/' element={
              <Home language={language}/>
              } />
            <Route path='/missions' element={
              <Missions language={language}/>
              } />
            <Route path='/about' element={
              <About language={language}/>
              } />
            <Route path='/contact' element={
              <Contact language={language}/>
              } />
            <Route path='/motorcycles' element={
              <Motorcycles language={language}/>
            } />
            <Route path='/consultancy' element={
              <Consultancy language={language}/>
            } />
            <Route path='/training' element={
              <Training language={language}/>
            } />
            <Route path='/cookies-policy' element={
              <Cookies language={language}/>
            } />
            <Route path='/legal-information' element={
              <Legal language={language}/>
            } />
            <Route path='/privacy-policy' element={
              <Privacy language={language}/>
            } />
            <Route path='/general-terms-and-conditions' element={
              <GTC language={language}/>
            } />
            <Route path='*' element={
              <Error language={language}/>
              } />
          </Routes>
          <Footer language={language}/>
        </div>
      </div>
    </div>
  );
}

export default App;
