import React from "react";
import Slider from "react-slick";
import Review from "./review";
import Erik from "../img/erik.jpg"
import Wim from "../img/wim.jpg"
import Wouter from "../img/wouter.jpg"
import Filip from "../img/filip.jpg"
import "./slick.css"; 
import "./slick-theme.css";


export default function ReviewGallery() {
  
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    }

    return (
    <div className="w-screen bg-black bg-opacity-10 dark:bg-white dark:bg-opacity-10 py-4 md:px-4 overflow-hidden">
        <div className="max-w-xs md:max-w-lg xl:max-w-6xl mx-auto">
        <Slider {...settings}>
            <Review picture={Erik} review="Wat betreft het samenwerken aan mijn CB750 van 1971 kan het volgende delen: Dirk is uiterst competent, creatief en nauwgezet. Met hem aan de moto werken is niet alleen leerrijk en leuk, het is een beleving, met resultaat. Bedankt Dirk!" author="Erik" bike="Honda CB750 Four"/>
            <Review picture={Wim} review="Wil je graag zelf aan je motor werken maar heb je niet de nodige kennis en tools ?
              Dirk heeft het juiste gereedschap en leert je veel bij hoe jij jouw motor zelf kan onderhouden.
              Bedankt voor de goede uitleg en een leerrijke ervaring." author="Wim" bike="Honda CBR900RR"/>
            <Review picture={Wouter} review="Dirk heeft mij vakkundig geholpen toen mijn Honda Varadero aan herstelling toe was.
              Hij heeft duidelijk kennis van zaken, is heel behulpzaam en heeft een passie voor moto's." author="Wouter" bike="Honda Varadero"/>
            <Review picture={Filip} review="Sleutelen aan je eigen moto inclusief de technische uitleg hoe iets moet gebeuren en het waarom en de
             garantie dat het professioneel gedaan is! Top idee! Geeft voldoening en dubbel plezier om daarna te toeren! Thx Dirk!" author="Filip" bike="Kawasaki Zephyr 750"/>
        </Slider>
        </div>
      </div>
    );
  
}