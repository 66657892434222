import React from "react";
import { NavLink } from "react-router-dom";

function BikeComp(props) {

    let content = {
        English: {
            h3one: "Next to sales I also have a passion for ",
            motorcycle: "motorcycles",
            h3two: "If you do as well: "
        },
        Nederlands: {
            h3one: "Naast sales heb ik ook een passie voor ",
            motorcycle: "motos",
            h3two: "Indien dit ook het geval is voor jou: "
        },
        Frans: {
            h3one: "A coté de “la vente” j’ai aussi une passion pour ",
            motorcycle: "motos",
            h3two: "Si c'est aussi le cas pour vous: "
        }
    }

    props.language === "English" ? (content = content.English) : (props.language === "Frans" ? (content = content.Frans) : (content = content.Nederlands));

    return (
        <div className="py-32">
            <div className=" w-full  bluegradient">
                <div className="py-8 mx-auto flex justify-between max-w-6xl flex-col md:flex-row">
                    <h3 className="text-start  dark:text-black mx-auto p-6">
                        {content.h3one} <span className="font-extrabold text-slate-200"> {content.motorcycle}</span>. <br/>
                        {content.h3two}
                    </h3>
                    <div className=" flex mx-auto items-center border-t-2 md:border-t-0 md:border-l-2 border-black">
                        <NavLink className={({isActive})=> (isActive ? 'activebutton' : 'linkbutton')} 
                        to="/motorcycles">
                            <div className="">
                                <li className="p-6 flex flex-row items-center">
                                    <p className="px-3 text-3xl font-extrabold text-slate-200">Let's talk about <br/> motorcycles</p>
                                </li>
                            </div>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BikeComp;