export default function Review(props){
    return (
        <div className="max-w-6xl mx-auto my-16 py-16 px-2 md:px-4 flex flex-col xl:flex-row justify-between items-center gap-8">
            <img src={props.picture} alt="review_picture" className="w-32 h-32 300:w-60 300:h-60 lg:w-80 lg:h-80 object-contain rounded-full"/>
            <div className="flex flex-col justify-center items-center gap-4">
                <q className="inline text-transparent text-lg xl:text-2xl bluegradient bg-clip-text font-medium text-center">{props.review}</q>
                <div className="w-full">
                    <p className="w-full text-right dark:text-white dark:text-opacity-75">- {props.author}</p>
                    <p className="w-full text-right dark:text-white dark:text-opacity-75">{props.bike}</p>
                </div>
            </div>
        </div>
    )
}