import React from "react";
import { NavLink } from "react-router-dom";

function BikeLink() {
    return (
        <div className=" flex mx-auto items-center">
            <NavLink className={({isActive})=> (isActive ? 'activebutton' : 'linkbutton')} 
            to="/motorcycles">
                <div className=" ">
                    <li className="rounded-full border-2 border-black dark:border-slate-100 hover:bluegradient hover:border-transparent hover:border-l-teal-600 hover:border-r-blue-600 dark:hover:border-transparent dark:hover:border-l-teal-600 dark:hover:border-r-blue-600 font-semibold text-lg text
                    flex flex-row ">
                        <p className="px-3 text-center">Let's talk about motorcycles</p>
                    </li>
                </div>
            </NavLink>
        </div>
    );
}

export default BikeLink;