import React from "react";
import {Helmet} from "react-helmet";
import ContactComp from "../component/ContactComp";
import Mission from "../component/Mission";

function Consultancy (props) {

    let content = {
        English: {
            title: "Support",
            explanation: "Do you need personalised support for your salesprojects (regional, product management, personal)?",
            one: "Launch new products",
            two: "Contact new markets or customers",
            three: "Reinforce the existing sales team",
            title2: "Team management",
            explanation2: "Is your salesdivision ready for the next step? Are you in need of an independent teammanager who can autonomously lead a sales team? Who understands your needs and can plan out a salesstratagy?",
            one2: "Launch a sales team",
            two2: "Evaluate and improve your team",
            three2: "Train your next Teammanager",
            title3: "Strategic advice",
            explanation3: "Are you in need of some strategic advice? Someone who looks at today and tomorrow with you?",
            one3: "Set up a vision and a mission",
            two3: "Work out a short term strategy",
            three3: "Work out a long term strategy"
        },
        Nederlands: {
            title: "Ondersteuning",
            explanation: "Heb jij nood aan gepersonaliseerde ondersteuning voor jouw verkoopsprojecten(regionaal, product management, persoonlijk)?",
            one: "Lancering van nieuwe producten",
            two: "Benaderen van nieuwe markten en/of klanten",
            three: "Versterken van het bestaand verkoops team",
            title2: "Team management",
            explanation2: "Is jou verkoopsafdeling klaar voor de volgende stap? Heb jij nood aan een onafhankelijke team die autonoom een verkoopsteam kan leiden? Iemand die jouw noden begrijpt en zelfstandig een verkoopsstrategie kan uitstippelen?",
            one2: "Een verkoopsteam opzetten",
            two2: "Jouw team evalueren en verder uit bouwen",
            three2: "Jouw volgende teammanager opleiden",
            title3: "Strategisch klankbord",
            explanation3: "Heb jij nood aan strategisch advies? Iemand die met jou meekijkt naar vandaag en morgen?",
            one3: "Jouw visie en missie mee opstellen",
            two3: "Korte termijn strategie uitwerken",
            three3: "Lange termijn strategie uitwerken"
        },
        Frans: {
            title: "Support",
            explanation: "Avez-vous besoin d'un soutien personnalisé pour vos projets de vente (régional, gestion de produit, personnellement)?",
            one: "Lancement de nouveaux produits",
            two: "Approcher de nouveaux marchés et/ou clients",
            three: "Renforcer l'équipe de vente existante",
            title2: "Gestion d’équipe",
            explanation2: "Votre force de vente est-elle prête à passer à l'étape suivante ? Avez-vous besoin d'un indépendant capable de diriger une équipe de vente de manière autonome ? Quelqu'un qui comprenne vos besoins et puisse développer de manière autonome une stratégie de vente?",
            one2: "Mettre en place une équipe de vente",
            two2: "Evaluer et construire votre équipe",
            three2: "Former votre prochain chef d'équipe",
            title3: "Être un partenaire stratégique",
            explanation3: "Vous avez besoin de conseils stratégiques ? De quelqu'un qui regarde avec vous aujourd'hui et demain?",
            one3: "Co-créer votre vision et votre mission",
            two3: "Développer une stratégie à court terme",
            three3: "Développer une stratégie à long terme"
        }
    }

    props.language === "English" ? (content = content.English) : (props.language === "Frans" ? (content = content.Frans) : (content = content.Nederlands));

    return (
        <div className="page">
            <Helmet>
                <title>Consultancy | Diverko</title>
            </Helmet>
            <div className="w-full bluegradient my-32 py-16  px-2 300:px-8 flex items-center justify-center">
                <h1 className="page__title">Consultancy </h1>
            </div>
            <div className="m-auto max-w-6xl flex-col justify-center py-32">
                    <Mission title={content.title} explanation={content.explanation} one={content.one} two={content.two} three={content.three} />
                    <Mission title={content.title2} explanation={content.explanation2} one={content.one2} two={content.two2} three={content.three2} />
                    <Mission title={content.title3} explanation={content.explanation3} one={content.one3} two={content.two3} three={content.three3} />
            </div>
            <ContactComp language={props.language}/>
        </div>
    );
}

export default Consultancy;