import React from "react";
import {Helmet} from "react-helmet";
import ContactComp from "../component/ContactComp";

function Contact (props) {

    let content = {
        English: {
            message1: "Nothing better than a conversation! ",
            message2: "Face-to-face, over the phone or otherwise:"
        },
        Nederlands: {
            message1: "Niets beter dan een gesprek! ",
            message2: "Face-to-face, telefonisch of op een andere manier:"
        },
        Frans: {
            message1: "Rien de mieux qu'une conversation! ",
            message2: "En face à face, par téléphone ou par tout autre moyen:"
        }
    }

    props.language === "English" ? (content = content.English) : (props.language === "Frans" ? (content = content.Frans) : (content = content.Nederlands));

    return (
        <div className="page">
            <Helmet>
                <title>Contact | Diverko</title>
            </Helmet>
            <div className="w-full bluegradient mt-32 py-16 flex items-center justify-center">
                <h1 className="max-w-6xl m-auto text-2xl md:text-6xl px-2 300:px-8 dark:text-black">{content.message1} <br/> {content.message2}<br/>
                <span className="text-slate-100">Let's make it happen.</span> </h1>
            </div>
            <ContactComp language={props.language}/>
        </div>
    );
}

export default Contact;