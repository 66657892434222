import React from "react";
import {Helmet} from "react-helmet";


function Privacy (props) {

    let content = {
        English: {
            title: "Privacy Policy",
            para: "DIVERKO, with company number 0778.964.240, with registered offica at Koetsweg 107, 3010 Leuven (hereinafter: “DIVERKO”) takes your privacy serious and takes all actions to protect your privacy in accordance with the law concerning the protection of people concerning the processing of personal data and the General Data Protection Regulation (GDPR). This privacy policy (hereinafter `Privacy policy`) is applied on all services, websites (hereinafter `services`) that are offered by DIVERKO.",
            h21: "1. What services do we offer?",
            para1: "DIVERKO offers multiple services of consultancy, training, salessupport, etc., with which you personal data is used to offer the best support, to inform you and/or to be more connected with you. We want to get to know you better and to personalize the services you selected. These services include invitations at your address, also the processing and the sharing of your personal data like described further in this privacy policy.",
            h22: "2. What personal data do we collect and process?",
            para2: "When you send us an e-mail, visit our website or offer you personal data in another way, we might collect and process the following personal data: firstname, name, address, (mobile) phonenumber, e-mailaddress and other data. DIVERKO tries to make the services offered to you as relevant as possible. DIVERKO keeps a profile based on you personal data to get an idea of your likely interests. We also collect internal documentation concerning interviews, evaluations, results and/or feedback (from DIVERKO zelf and/or from customers), etc. On this website we do not u;se cookies. We can use your data for purposes in accordance with this privacy policy.",
            h23: "3. Why do we process personal data?",
            para3: "Your personal data can be used:",
            li31: "To inform our customers concerning salessupport, or concerning our activities as consultant, projectexecutioner and/or trainer.",
            li32: "To improve the content and the execution of our services.",
            li33: "For our administration.",
            li34: "For direct marketing purposes.",
            li35: "To indentify individual purposes and to personalize the services and invitation.",
            li36: "To conduct market research.",
            li37: "To prevent and detect abuse or fraud.",
            li38: "to comply with laws and regulations",
            para32: "We may use your personal data for new purposes not yet provided for in our Privacy Policy. In that case, we will contact you before using your data for these new purposes, to inform you of the changes to our rules and to give you the opportunity to refuse your participation.",
            h24: "4. With whom do we share your personal data?",
            customers: "customers",
            para41: "We may share your personal data with our clients in the context of our consulting assignments, or in the context of our activities as project implementers and/or trainers.",
            suppliers: "suppliers",
            para42: "We work with other companies, agents or service providers (`Suppliers`) to perform certain services on our behalf or to assist us in providing Services to you and our clients. For example, we use Suppliers to provide marketing, communications, infrastructure, financial and IT services, to personalize and optimize our Service, to conduct our administration, provide customer service, collect debts, analyze and improve data. In providing such services, these Suppliers may have access to your personal data or other information. We enter into processing agreements with these Suppliers so that your personal data is also protected in this relationship.",
            restructuring: "restructuring",
            para43: "We reserve the right to transfer data, including personal data, in connection with any reorganization, restructuring, merger, sale or other transfer of business assets, provided that the receiving party agrees to process your personal data in accordance with our Privacy Policy.",
            others: "other",
            para44: "We may also share or transfer personal data, including the content of your communications:",
            li41: "to comply with the law or respond to legal process or legal requests, including law enforcement and government agencies",
            li42: "to detect, prevent or otherwise address fraud and technical security problems",
            li43: "to protect DIVERKO's rights, including the enforcement of our terms of use",
            para45: "Your personal data may also be shared outside of Europe. DIVERKO undertakes to appoint only controllers and/or processors outside the European Economic Area that provide adequate safeguards for personal data security and protection in accordance with applicable privacy laws. The complete and updated list of decisions on the appropriate nature of Data Protection in third countries approved by the EU Commission can be consulted at http://ec.europa.eu/justice/data-protection/internationaltransfers/adequacy/index_en.htm. DIVERKO will not provide your data to other third parties without your prior consent.",
            h25: "5. How long do we store your personal data?",
            para5: "In principle, DIVERKO retains your personal data for a period of 5 years, with periodic notifications to You regarding Your rights in this regard.",
            h26: "6. What are your rights?",
            para6: "You have the following rights, upon written request to diverko@proximus.be.",
            li61: "right to information",
            li62: "right of inspection",
            li63: "right of rectification",
            li64: "right of opposition",
            li65: "right to be forgotten",
            li66: "right to portability and data migration",
            para62: "In addition, we will alert you if personal data has been hacked or there is a possible data breach.",
            h27: "7. General",
            para7: "Information on privacy protection can be obtained from the Data Protection Authority (www.gegevensbeschermingsautoriteit.be). We have taken appropriate technical and organizational measures to prevent the loss, unlawful processing or alteration of information we receive from you.",
            para72: "If you wish to respond to any of the actions described above, please contact us by letter at the address listed at the top of this Privacy Policy, or by e-mail (diverko@proximus.be). This Privacy Policy is governed by Belgian law. Disputes relating to this Privacy Policy are subject to the exclusive jurisdiction of the Leuven courts."
        },
        Nederlands: {
            title: "Privacy Beleid",
            para: "DIVERKO, met ondernemingsnummer 0778.964.240, met maatschappelijke zetel te Koetsweg 107, 3010 Leuven (hierna: “DIVERKO”) neemt uw privacy ter harte en treft dan ook alle passende maatregelen om uw privacy te beschermen overeenkomstig de Wet betreffende de bescherming van natuurlijke personen met betrekking tot de verwerking van persoonsgegevens en de Algemene Verordening Gegevensbescherming (“GDPR”). Dit privacybeleid (hierna “Privacybeleid”) is van toepassing op alle diensten, websites (hierna tezamen “Diensten”) die worden aangeboden door DIVERKO.",
            h21: "1. Welke diensten bieden wij aan?",
            para1: "DIVERKO biedt diverse Diensten aan van consultantie, training, verkoopsondersteuning, enz., waarbij we uw persoonsgegevens gebruiken om de beste ondersteuning aan u te bieden, om u te informeren en/of om meer betrokkenheid samen met u te creëren. Wij willen u beter leren kennen en de door u gekozen Diensten afstemmen op uw persoonlijke voorkeuren. Deze Diensten omvatten uitnodigingen aan uw adres, alsook het verwerken en het delen van uw persoonsgegevens zoals in dit Privacybeleid verder omschreven.",
            h22: "2. Welke persoonsgegevens verzamelen en verwerken we?",
            para2: "Wanneer u ons een e-mail stuurt, onze website bezoekt of ons op een andere wijze persoonsgegevens over uzelf bezorgt, verzamelen en verwerken we mogelijk volgende persoonsgegevens : voornaam, naam, adres, (mobiel) telefoonnummer, e-mailadres en andere gegevens. DIVERKO streeft er naar de Diensten die ze aanbiedt zo relevant mogelijk te maken voor u. DIVERKO houdt een profiel bij op basis van uw persoonsgegevens om een beeld te krijgen van uw vermoedelijke interesses. Wij houden eveneens interne rapporten bij in het kader van interviews, evaluaties, resultaten en/of feedback (van DIVERKO zelf en/of van klanten), etc. Op onze website gebruiken we GEEN cookies. Wij kunnen uw persoonsgegevens gebruiken voor doeleinden die in overeenstemming zijn met dit Privacybeleid.",
            h23: "3. Waarom verwerken wij persoonsgegevens?",
            para3: "Uw persoonsgegevens kunnen gebruikt worden:",
            li31: "om aan onze klanten mee te delen in het kader van verkoopsondersteuning, dan wel in het kader van onze activiteiten als consultant of projectuitvoerder en/of trainer.",
            li32: "om de inhoud en de uitvoering van onze Diensten te verbeteren",
            li33: "voor onze administratie",
            li34: "voor direct marketingdoeleinden",
            li35: "om individuele voorkeuren te identificeren en de Diensten en uitnodigingen te personaliseren",
            li36: "om marktonderzoek te verrichten",
            li37: "om misbruik of fraude te voorkomen en op te sporen",
            li38: "ter naleving van wet- en regelgeving",
            para32: "Wij kunnen uw persoonsgegevens gebruiken voor nieuwe doeleinden die nog niet voorzien zijn in ons Privacybeleid. In dat geval, zullen wij u contacteren alvorens uw gegevens te gebruiken voor deze nieuwe doeleinden, om u op de hoogte te stellen van de wijzigingen aan onze regels en om u de kans te bieden uw deelname te weigeren.",
            h24: "4. Met wie delen wij uw persoonsgegevens?",
            customers: "klanten",
            para41: "We kunnen uw persoonsgegevens delen met onze klanten in het kader van onze consultancy opdrachten, dan wel in het kader van onze activiteiten als projectuitvoerder en/of trainer.",
            suppliers: "leveranciers",
            para42: "We werken samen met andere bedrijven, tussenpersonen of dienstverleners (`Leveranciers`) om bepaalde diensten namens ons uit te voeren of ons te helpen bij de levering van Diensten aan u en onze klanten. We gebruiken bijvoorbeeld Leveranciers om marketing-, communicatie-, infrastructuur-, financiële en IT-diensten te leveren, om onze service te personaliseren en optimaliseren, onze administratie te voeren, klantenservice te leveren, schulden in te vorderen, gegevens te analyseren en verbeteren. Het is mogelijk dat deze Leveranciers bij het verlenen van dergelijke diensten toegang krijgen tot uw persoonsgegevens of andere informatie. Wij sluiten verwerkingsovereenkomsten met deze Leveranciers af zodat uw persoonsgegevens ook in deze verhouding beschermd zijn.",
            restructuring: "herstructurering",
            para43: "We behouden ons het recht voor om in verband met welke reorganisatie, herstructurering, fusie, verkoop of andere overdracht van bedrijfsmiddelen dan ook gegevens over te dragen, inclusief persoonsgegevens, mits de ontvangende partij ermee instemt om uw persoonsgegevens te verwerken overeenkomstig ons Privacybeleid.",
            others: "andere",
            para44: "Het is ook mogelijk dat wij persoonsgegevens delen of doorgeven, inclusief de inhoud van uw communicaties:",
            li41: "om te voldoen aan de wet of te reageren op een juridische procedure of wettelijke verzoeken, met inbegrip van rechtshandhaving en overheidsinstanties",
            li42: "om fraude en technische beveiligingsproblemen te detecteren, te voorkomen of anderszins aan te pakken",
            li43: "om de rechten van DIVERKO te beschermen, waaronder de handhaving van onze gebruiksvoorwaarden",
            para45: "Uw persoonsgegevens kunnen ook buiten Europa gedeeld worden. DIVERKO verbindt zich ertoe enkel verwerkingsverantwoordelijken en/of verwerkers buiten de Europese Economische Ruimte aan te stellen die in overeenstemming met de toepasselijke privacywetgeving voldoende garanties bieden inzake beveiliging en bescherming van persoonsgegevens. De volledige en bijgewerkte lijst van de beslissingen inzake de gepaste aard van Gegevensbescherming in de derde landen goedgekeurd door de Commissie van de EU kan worden geraadpleegd op http://ec.europa.eu/justice/data-protection/internationaltransfers/adequacy/index_en.htm. DIVERKO zal uw gegevens niet aan andere derden verstrekken zonder uw voorafgaande toestemming. ",
            h25: "5. Hoe lang bewaren wij uw persoonsgegevens?",
            para5: "In principe bewaart DIVERKO uw persoonsgegevens voor een termijn van 5 jaar, met periodieke notificaties aan U inzake Uw rechten hieromtrent.",
            h26: "6. Wat zijn uw rechten?",
            para6: "U hebt de volgende rechten, na schriftelijke aanvraag op diverko@proximus.be.",
            li61: "recht op informatie",
            li62: "recht op inzage",
            li63: "recht op correctie",
            li64: "recht van verzet",
            li65: "recht om vergeten te worden",
            li66: "recht op portabiliteit en datamigratie",
            para62: "Daarenboven zullen wij u waarschuwen indien persoonsgegevens gehackt zijn of er een mogelijk datalek is.",
            h27: "7. Algemeen",
            para7: "Informatie over de bescherming van de persoonlijke levenssfeer kan u bekomen bij de Gegevensbeschermingsautoriteit (www.gegevensbeschermingsautoriteit.be).  Wij hebben passende technische en organisatorische maatregelen getroffen om het verlies, onrechtmatige verwerking of de wijziging te voorkomen van informatie die wij van u ontvangen. ",
            para72: "Als u wenst te reageren op een van de hiervoor beschreven handelingen, kunt u ons contacteren per brief op het adres vermeld bovenaan dit Privacybeleid, of per e-mail (diverko@proximus.be).Op dit Privacybeleid is Belgisch recht van toepassing. Geschillen met betrekking tot dit Privacybeleid vallen onder de uitsluitende bevoegdheid van de rechtbanken te Leuven."
        },
        Frans: {
            title: "Politique de Confidentialité",
            para: "DIVERKO, dont le numéro d'entreprise est 0778.964.240, et dont le siège social est situé Koetsweg 107, 3010 Leuven (ci-après ` DIVERKO `) prend votre vie privée à cœur et prend donc toutes les mesures appropriées pour protéger votre vie privée conformément à la loi relative à la protection des personnes à l'égard du traitement des données à caractère personnel et au règlement général sur la protection des données (` RGPD `). La présente politique de confidentialité (ci-après ` Politique de confidentialité `) s'applique à tous les services, sites internet (ci-après collectivement ` Services `) proposés par DIVERKO.",
            h21: "1. Quels sont les services que nous proposons?",
            para1: "DIVERKO propose différents Services de conseil, de formation, d'aide à la vente, etc., où nous utilisons vos données personnelles pour vous apporter le meilleur soutien, vous informer et/ou créer plus d'engagement avec vous. Nous souhaitons mieux vous connaître et adapter les services que vous avez choisis à vos préférences personnelles. Ces Services comprennent des invitations à votre adresse, ainsi que le traitement et le partage de vos données personnelles comme décrit plus en détail dans la présente Politique de confidentialité.",
            h22: "2. Quelles sont les données personnelles que nous collectons et traitons?",
            para2: "Lorsque vous nous envoyez un e-mail, visitez notre site Internet ou nous fournissez autrement des données personnelles vous concernant, nous pouvons collecter et traiter les données personnelles suivantes : prénom, nom, adresse, numéro de téléphone (mobile), adresse e-mail et autres données. DIVERKO s'efforce de rendre les services qu'elle offre aussi pertinents que possible pour vous. DIVERKO maintient un profil basé sur vos données personnelles afin de se faire une idée de vos intérêts probables. Nous conservons également des rapports internes dans le cadre d'entretiens, d'évaluations, de résultats et/ou de feedbacks (de la part de DIVERKO elle-même et/ou des clients), etc. Sur notre site Internet, nous n'utilisons PAS de cookies. Nous pouvons utiliser vos données personnelles à des fins conformes à la présente politique de confidentialité.",
            h23: "3. Pourquoi traitons-nous les données personnelles?",
            para3: "Vos données personnelles peuvent être utilisées:",
            li31: "pour communiquer avec nos clients dans le cadre de l'assistance à la vente, ou dans le cadre de nos activités de consultant ou de réalisateur de projets et/ou de formateur.",
            li32: "pour améliorer le contenu et les performances de nos services",
            li33: "pour notre administration",
            li34: "à des fins de marketing direct",
            li35: "pour identifier les préférences individuelles et personnaliser les services et les invitations",
            li36: "pour réaliser des études de marché",
            li37: "pour prévenir et détecter les abus ou les fraudes",
            li38: "pour se conformer aux lois et règlements",
            para32: "Nous pouvons aussi utiliser vos données personnelles à de nouvelles fins non encore prévues dans notre politique de confidentialité. Dans ce cas, nous vous contacterons avant d'utiliser vos données à ces nouvelles fins, afin de vous informer des modifications apportées à nos règles et de vous donner la possibilité de refuser votre participation.",
            h24: "4. Avec qui partageons-nous vos données personnelles ?",
            customers: "Des clients",
            para41: "Nous pouvons partager vos données personnelles avec nos clients dans le cadre de nos missions de conseil, ou dans le cadre de nos activités de mise en œuvre de projets et/ou de formateurs.",
            suppliers: "Des fournisseurs",
            para42: "Nous travaillons avec d'autres sociétés, intermédiaires ou prestataires de services (` Fournisseurs `) pour exécuter certains services en notre nom ou pour nous aider à fournir des Services à vous et à nos clients. Par exemple, nous faisons appel à des Fournisseurs pour fournir des services de marketing, de communication, d'infrastructure, financiers et informatiques, pour personnaliser et optimiser notre Service, mener notre administration, fournir un service à la clientèle, collecter des dettes, analyser et améliorer les données. En fournissant ces services, ces fournisseurs peuvent avoir accès à vos données personnelles ou à d'autres informations. Nous concluons des accords de traitement avec ces Fournisseurs afin que vos données personnelles soient également protégées dans cette relation.",
            restructuring: "Pour cause de restructuration",
            para43: "Nous nous réservons le droit de transférer des données, y compris des données personnelles, dans le cadre d'une réorganisation, d'une restructuration, d'une fusion, d'une vente ou de tout autre transfert d'actifs commerciaux, à condition que la partie destinataire accepte de traiter vos données personnelles conformément à notre politique de confidentialité.",
            others: "Avec d'autres",
            para44: "Nous pouvons également partager ou transférer des données personnelles, y compris le contenu de vos communications:",
            li41: "pour nous conformer à la loi ou répondre à un processus légal ou à des demandes légales, y compris de la part d'organismes chargés de l'application de la loi et d'organismes gouvernementaux.",
            li42: "pour détecter, prévenir ou traiter autrement la fraude et les problèmes de sécurité technique.",
            li43: "pour protéger les droits de DIVERKO, y compris l'application de nos conditions d'utilisation.",
            para45: "Vos données personnelles peuvent également être partagées en dehors de l'Europe. DIVERKO s'engage à ne désigner que des contrôleurs et/ou des processeurs de données situés en dehors de l'Espace économique européen qui offrent des garanties adéquates en matière de sécurité et de protection des données personnelles conformément aux lois applicables en matière de protection de la vie privée. La liste complète et actualisée des décisions sur le caractère adéquat de la protection des données dans les pays tiers approuvées par la Commission européenne peut être consultée sur http://ec.europa.eu/justice/data-protection/internationaltransfers/adequacy/index_en.htm. DIVERKO ne divulguera pas vos données à d'autres tiers sans votre consentement préalable. ",
            h25: "5. Combien de temps conservons-nous vos données personnelles?",
            para5: "En principe, DIVERKO conserve vos données personnelles pendant une période de 5 ans, avec des notifications périodiques à votre intention concernant vos droits à cet égard.",
            h26: "6. Quels sont vos droits?",
            para6: "Vous disposez des droits suivants, sur demande écrite à diverko@proximus.be.",
            li61: "droit à l'information",
            li62: "droit d'inspection",
            li63: "droit de rectification",
            li64: "droit d'oppositiont",
            li65: "droit à l'oubli",
            li66: "droit à la portabilité et à la migration des données",
            para62: "En outre, nous vous alerterons si des données personnelles ont été piratées ou s'il y a une possible violation de données.",
            h27: "7. Généralités",
            para7: "Des informations sur la protection de la vie privée peuvent être obtenues auprès de l'autorité de protection des données (www.autoriteprotectiondonnees.be). Nous avons pris des mesures techniques et organisationnelles appropriées pour éviter la perte, le traitement illégal ou l'altération des informations que nous recevons de votre part.",
            para72: "Si vous souhaitez réagir à l'une des actions décrites ci-dessus, veuillez nous contacter par courrier à l'adresse indiquée en haut de la présente politique de confidentialité, ou par e-mail (diverko@proximus.be). La présente politique de confidentialité est régie par le droit belge. Les litiges relatifs à cette politique de confidentialité sont soumis à la compétence exclusive des tribunaux de Louvain."
        }
    }

    props.language === "English" ? (content = content.English) : (props.language === "Frans" ? (content = content.Frans) : (content = content.Nederlands));

    return (
    <div className="page">
        <Helmet>
            <title>{content.title} | Diverko</title>
        </Helmet>
        <div className="py-32 h-full min-w-lg max-w-6xl m-auto px-2 300:px-8 flex flex-col gap-8 text text-xs lg:text-xl">
            <h1>{content.title}</h1>
            <p className="text pt-8">
                {content.para}
            </p>
            <h2>{content.h21}</h2>
            <p>{content.para1}</p>
            <h2>{content.h22}</h2>
            <p>{content.para2}</p>
            <h2>{content.h23}</h2>
            <p>{content.para3}</p>
            <ul className="list-disc ml-6">
                <li>{content.li31}</li>
                <li>{content.li32}</li>
                <li>{content.li33}</li>
                <li>{content.li34}</li>
                <li>{content.li35}</li>
                <li>{content.li36}</li>
                <li>{content.li37}</li>
                <li>{content.li38}</li>
            </ul>
            <p>{content.para32}</p>
            <h2>{content.h24}</h2>
            <p>{content.customers}</p>
            <p>{content.para41}</p>
            <p>{content.suppliers}</p>
            <p>{content.para42}</p>
            <p>{content.restructuring}</p>
            <p>{content.para43}</p>
            <p>{content.others}</p>
            <p>{content.para44}</p>
            <ul className="list-disc ml-6">
                <li>{content.li41}</li>
                <li>{content.li42}</li>
                <li>{content.li43}</li>
            </ul>
            <p>{content.para45}</p>
            <h2>{content.h25}</h2>
            <p>{content.para5}</p>
            <h2>{content.h26}</h2>
            <p>{content.para6}</p>
            <ul className="list-disc ml-6">
                <li>{content.li61}</li>
                <li>{content.li62}</li>
                <li>{content.li63}</li>
                <li>{content.li64}</li>
                <li>{content.li65}</li>
                <li>{content.li66}</li>
            </ul>
            <p>{content.para62}</p>
            <h2>{content.h27}</h2>
            <p>{content.para7}</p>
            <p>{content.para72}</p>
        </div>
    </div>
    );
}

export default Privacy;