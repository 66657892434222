import React from "react";
import ContactForm from "../component/contactForm";
import logo from "../img/logo.png";
import {FaRegPaperPlane, FaLinkedin, FaWhatsapp } from "react-icons/fa";
import BikeLink from "./BikeLink";

function ContactComp(props) {

    return (
        <div className="py-32 px-2 300:px-8 max-w-6xl m-auto">
                <div className="w-full h-fulll flex flex-col lg:flex-row justify-between">
                    <div className="w-full flex flex-col justify-between">
                        <div className="w-full lg:w-auto bg-black dark:bg-slate-100 bg-opacity-10 dark:bg-opacity-10 p-8">
                            <div className="flex flex-row items-center select-none py-8">
                                <img src={logo} alt="logo" className=" w-16 300:w-20 pr-3"/>
                                <h1 className="italic">Diver<span className="text-blue-500">ko</span></h1>
                            </div>
                            <div className="flex flex-col items-start justify-center">
                                <div className="contactLink">
                                    <FaRegPaperPlane size={25} className=""/>
                                    <p className=""><a href="mailto:Diverko@proximus.be">Diverko@proximus.be</a></p>

                                </div>
                                <div className="contactLink select-all">
                                    <FaWhatsapp size={25} className=""/>
                                    <p className=""><a href="https://wa.me/32487504647" target="_blank" rel="noreferrer noopener" >+32 487 50 46 47</a></p>

                                </div>
                                <div className="contactLink">
                                    <FaLinkedin size={25} className=" "/>
                                    <p className=""><a href="https://www.linkedin.com/in/vandeputdirk/" target="_blank" rel="noreferrer noopener" >LinkedIn/Diverko</a></p>

                                </div>
                                <div className="contactLink">
                                    <BikeLink />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full p-8 flex flex-col items-start justify-start">
                        <div className="w-full py-8">
                            <h1 >Let's 
                                <span className="before:absolute before:-inset-1 before:-skew-y-3 before:bluegradient relative inline-block mx-4 px-4">
                                    <span className="relative">talk</span>
                                </span>
                            </h1>
                        </div>
                        <ContactForm language={props.language}/>
                    </div>
                </div>
            </div>
    );
}

export default ContactComp;