import React from 'react';
import {Helmet} from "react-helmet";

export default function GTC(props) {

    let content = {
        English: {
            title: "GENERAL TERMS AND CONDITIONS",
            h21: "1. Scope of the Agreement",
            para1: "DIVERKO shall perform the Assignment in complete independence and to its own ability. He chooses the manner and means by which he will perform the Services. However, he must take into account the general policies communicated to him by the Client. DIVERKO chooses its own working hours within the framework of the Contract; the Client cannot impose any restriction on it in terms of timetable and organization. However, DIVERKO shall ensure sufficient availability for optimal performance of the Services. No hierarchical relationship exists between the Client and DIVERKO, nor any other relationship other than that described in the Agreement. DIVERKO, as self-employed, retains responsibility for compliance with social security and tax obligations. ",
            h22: "2. Implementation",
            para2: "DIVERKO is responsible for the execution of the Agreement. Within the scope of the Contract, DIVERKO shall be free to choose its place of work, but at the request of the Customer and in order to ensure optimum coordination, DIVERKO shall perform its activities at the registered office of the Customer or be present there as much as possible. If DIVERKO finds itself unable to continue performing the Agreement for a period longer than 1 month, DIVERKO shall notify the Customer. DIVERKO must in this case, together with the Customer, seek a solution that both parties can agree to.",
            h23: "3. Compensation",
            para3: "The fee for Services delivered shall be fixed at the beginning of the contract. It is exclusive of VAT and does not include travel and other administrative costs. These are established monthly according to the agreements made and/or according to written approval of the Client for specific costs. The remuneration and expense allowances will be negotiated annually between the parties and are subject to the standards of official wage indexation in the interim.",
            h24: "4. Billing",
            para4: "The fee for the Assignment will be invoiced monthly by DIVERKO to the Client. All invoices are payable within a period of 15 days from invoice date. The Customer undertakes to pay the invoice on time and by transferring it to DIVERKO's account with IBAN no. BE07 7370 5993 8666 (BIC: KREDBEBB). DIVERKO shall be entitled to suspend performance of the Agreement in the event of non-payment of invoices. ",
            h25: "5. Legal Obligations",
            para5: "DIVERKO confirms to comply with all legal and regulatory provisions in order to properly perform its Assignment. DIVERKO declares to comply with the conditions of Belgian social and fiscal legislation. ",
            h26: "6. Assignment",
            para6: "None of the rights and obligations arising from this Agreement may be transferred to a third party except with the express consent of the other parties.",
            h27: "7. Non-competition clause",
            para7: "DIVERKO undertakes to refrain from conducting, directly or indirectly, competitive activities within the Customer's market and from approaching and disposing, directly or indirectly, of customers of the Customer for the duration of the current contract",
            h28: "8. Confidentiality",
            para8: "Parties shall treat as confidential all information that they acquire with regard to the other party during the formation and execution of the Agreement. The parties will use the confidential information exclusively in the performance of the Agreement. Such confidential information shall not be disclosed or made available to third parties without the prior consent of the other party, except in the case where the information is generally known and publicly available. DIVERKO declares that this duty of confidentiality further extends to its possible organs, executing agents and personnel. ",
            h29: "9. Intellectual rights and property rights",
            para9: "DIVERKO automatically and by operation of law assigns to the Customer all legally transferable copyrights or other intellectual rights to all information developed in the context of the Contract, without any additional compensation being payable by the Customer. DIVERKO shall indemnify the Customer against any claim, based on infringement or alleged infringement of any intellectual property right due to the use of the rights granted. The Customer shall immediately notify DIVERKO of such claim in writing. All documents used and prepared by DIVERKO under the Agreement, are the property of the Customer. DIVERKO shall hand over these documents at the Customer's first request or in any case upon termination of the Agreement. ",
            h210: "10. Liability",
            para10: "DIVERKO shall be liable to the Customer for the damage caused by its fault or negligence in the performance of its Services, the violation of the articles in the Contract or the violation of any statutory provision. DIVERKO confirms that it has covered its liability to the Client with an approved liability insurer for a minimum coverage of EUR 50,000. At the Customer's simple request, DIVERKO shall provide the necessary supporting documents. ",
            h211: "11. Waiver",
            para11: "The non-claim of a right or the non-application of a sanction by either party shall in no way imply a waiver of that right. A party may waive its rights only in writing.",
            h212: "12. Nullity",
            para12: "The validity of the Agreement shall not be affected by the nullity of any of its clauses; to the extent possible and subject to the mutual agreement of the Parties, the clause which is declared null and void shall be replaced by a clause which corresponds as closely as possible to the economic intent and interpretation of the Parties to the Agreement. ",
            h213: "13. Dispute resolution",
            para13: "The Parties undertake to resolve any dispute relating to the Agreement, through the intervention of a mediator. If they cannot be resolved by the parties, the disputes will be submitted to the courts of Leuven. Belgian law is applicable to the Agreement.",
        },
        Nederlands: {
            title: "ALGEMENE MODALITEITEN EN VOORWAARDEN",
            h21: "1. Draagwijdte van de overeenkomst",
            para1: "DIVERKO voert de Opdracht uit in alle onafhankelijkheid en naar eigen vermogen. Hij kiest de wijze waarop en de middelen waarmee hij de Diensten zal vervullen. Hij dient evenwel rekening te houden met de algemene beleidslijnen die hem door de Opdrachtgever werden meegedeeld. DIVERKO kiest zelf zijn werktijden in het kader van de Overeenkomst, de Opdrachtgever kan hem geen beperking opleggen qua tijdschema en organisatie. DIVERKO zorgt evenwel voor voldoende beschikbaarheid voor een optimale uitvoering van de Diensten. Tussen de Opdrachtgever en de DIVERKO bestaat er geen hiërarchische verhouding, noch enige andere relatie dan deze beschreven in de Overeenkomst. DIVERKO houdt als zelfstandige, de verantwoordelijkheid voor het naleven van de verplichtingen inzake sociale zekerheid en belastingen. ",
            h22: "2. Uitvoering",
            para2: "DIVERKO staat in voor de uitvoering van de Overeenkomst. In het kader van de Overeenkomst heeft DIVERKO een vrije keuze aangaande de werkplek, doch op verzoek van de Opdrachtgever en teneinde een optimale coördinatie te verzekeren, zal de DIVERKO zijn activiteiten uitvoeren op de zetel van de Opdrachtgever of zoveel als mogelijk daar aanwezig zijn. Indien DIVERKO zich gedurende een periode langer dan 1 maand in de onmogelijkheid bevindt de Overeenkomst verder uit te voeren, dan zal DIVERKO de Opdrachtgever hiervan in kennis stellen. DIVERKO moet in dit geval, samen met de Opdrachtgever, naar een oplossing zoeken die beide partijen  kunnen goedkeuren.",
            h23: "3. Vergoeding",
            para3: "De vergoeding voor geleverde Diensten wordt bij het begin van de overeenkomst vastgelegd. Deze is exclusief BTW en omvat geen verplaatsingskosten en andere administratieve kosten. Deze worden maandelijks opgesteld volgende de gemaakte afspraken en/of volgens schriftelijke goedkeuring van de Opdrachtgever voor specifieke kosten. De vergoeding en de onkostenvergoedingen zullen jaarlijks tussen partijen worden overlegd en zijn tussentijds onderhevig aan de normen van de officiële loonindexering.",
            h24: "4. Facturatie",
            para4: "De vergoeding voor de Opdracht zal maandelijks door DIVERKO worden gefactureerd aan de Opdrachtgever. Alle facturen zijn betaalbaar binnen een termijn van 15 dagen na factuurdatum. De Opdrachtgever verbindt zich ertoe de factuur tijdig te voldoen en dit door storting op de rekening van DIVERKO met IBANnr BE07 7370 5993 8666 (BIC: KREDBEBB). DIVERKO heeft het recht de uitvoering van de Overeenkomst op te schorten bij niet-betaling van de facturen. ",
            h25: "5. Wettelijke verplichtingen",
            para5: "DIVERKO bevestigt te voldoen aan alle wettelijke en reglementaire bepalingen om haar Opdracht naar behoren uit te oefenen. DIVERKO verklaart te voldoen aan de voorwaarden van de Belgische sociale en fiscale wetgeving. ",
            h26: "6. Overdracht",
            para6: "Geen van de rechten en plichten voortvloeiend uit deze Overeenkomst kunnen worden overgedragen aan een derde, tenzij met de uitdrukkelijke toestemming van de andere partijen.",
            h27: "7. Niet-concurrentie beding",
            para7: "DIVERKO verbindt zich ertoe zich te onthouden van het voeren, op directe of indirecte wijze, van concurrentiële actviteiten binnen de markt van de Opdrachtgever en van het benaderen en afwerven, op directe of indirecte wijze van cliënteel van de Opdrachtgever en dit voor de termijn van het lopende contract.",
            h28: "8. Geheimhouding",
            para8: "Partijen zullen alle informatie die zij m.b.t. de andere partij verwerven bij de totstandkoming en de uitvoering van de Overeenkomst als vertrouwelijke informatie beschouwen. Partijen zullen de vertrouwelijke informatie uitsluitend gebruiken in de uitvoering van de Overeenkomst. Deze vertrouwelijke informatie zal niet openbaar worden gemaakt of ter beschikking worden gesteld aan derden zonder de voorafgaandelijke toestemming van de andere partij, tenzij in het geval de informatie algemeen bekend en publiek beschikbaar is. DIVERKO verklaart dat deze geheimhoudingsplicht zich verder uitstrekt tot zijn mogelijke organen, uitvoeringsagenten en personeel. ",
            h29: "9. Intellectuele rechten en eigendomsrecht",
            para9: "DIVERKO draagt automatisch en van rechtswege alle wettelijke overdraagbare auteursrechten of andere intellectuele rechten op alle informatie, ontwikkeld in het kader van de Overeenkomst, over aan de Opdrachtgever, zonder dat deze hiervoor enige bijkomende vergoeding verschuldigd is. DIVERKO vrijwaart de Opdrachtgever tegen elke vordering, op grond van inbreuk of vermeende inbreuk op enig intellectueel eigendomsrecht wegens het gebruik van de verleende rechten. De Opdrachtgever zal DIVERKO van dergelijke vordering onmiddellijk schriftelijk op de hoogte brengen. Alle gebruikte en in het kader van de Overeenkomst door DIVERKO opgestelde documenten, zijn eigendom van de Opdrachtgever. DIVERKO zal op eerste verzoek van de Opdrachtgever of in ieder geval bij beëindiging van de overeenkomst, deze documenten overhandigen. ",
            h210: "10. Aansprakelijkheid",
            para10: "DIVERKO is ten aanzien van de Opdrachtgever aansprakelijk voor de schade veroorzaakt door zijn fout of nalatigheid in de uitvoering van zijn Diensten, de schending van de artikelen in de Overeenkomst of de schending van enige wettelijke bepaling. DIVERKO bevestigt zijn aansprakelijkheid ten aanzien van de Opdrachtgever te hebben gedekt bij een erkend aansprakelijkheidsverzekeraar en dit voor een minimale dekking van 50.000 EUR. Op eenvoudig verzoek van de Opdrachtgever zal DIVERKO de nodige bewijsstukken voorleggen. ",
            h211: "11. Afstand",
            para11: "Het niet-opeisen van een recht of het niet-toepassen van een sanctie door een van beide partijen houdt geenszins een afstand van dat recht in. Een partij kan enkel schriftelijk afstand doen van haar rechten.",
            h212: "12. Nietigheid",
            para12: "De geldigheid van de Overeenkomst wordt niet aangetast door de nietigheid van een van zijn clausules; voor zover dat mogelijk is en mits een wederzijds akkoord van de Partijen, wordt de clausule die nietig wordt verklaard, vervangen door een clausule die zo strikt mogelijk aanleunt bij de economische bedoeling en interpretatie van de Partijen van de Overeenkomst. ",
            h213: "13. Geschillenregeling",
            para13: "De partijen verbinden er zich toe ieder geschil met betrekking tot de Overeenkomst, door tussenkomst van een bemiddelaar op te lossen. Indien zij niet door partijen kunnen worden opgelost, zullen de geschillen voorgelegd worden aan de rechtbanken van Leuven. Het Belgisch recht is van toepassing op de overeenkomst.",
        },
        Frans: {
            title: "CONDITIONS GÉNÉRALES",
            h21: "1. Portée du contrat",
            para1: "DIVERKO exécute la mission en toute indépendance et selon ses propres capacités. Il choisit la manière et les moyens par lesquels il exécute les services. Toutefois, il tient compte des conditions générales qui lui sont communiquées par le client. DIVERKO choisit lui-même ses horaires de travail dans le cadre du Contrat ; le Client ne peut lui imposer aucune restriction en termes d'horaire et d'organisation. Toutefois, DIVERKO veille à assurer une disponibilité suffisante pour un fonctionnement optimal des services. Il n'existe aucune relation hiérarchique entre le Client et DIVERKO, ni aucune autre relation que celle décrite dans le Contrat. DIVERKO, en tant que consultant indépendant, conserve la responsabilité du respect des obligations sociales et fiscales.",
            h22: "2. Exécution",
            para2: "DIVERKO est responsable de l'exécution du contrat. Dans le cadre du contrat, DIVERKO est libre de choisir son lieu de travail, cependant, à la demande du client et afin d'assurer une coordination optimale, DIVERKO exercera ses activités au siège social du client ou y sera présente dans la mesure du possible. Si DIVERKO se trouve dans l'impossibilité de poursuivre l'exécution du contrat pendant une période supérieure à 1 mois, DIVERKO en informera le client. Dans ce cas, DIVERKO recherche avec le client une solution acceptable pour les deux parties.",
            h23: "3. Rémunération",
            para3: "La rémunération des services fournis est déterminée au début du contrat. Il est hors TVA et ne comprend pas les frais de déplacement et autres frais administratifs. Ceux-ci sont préparés mensuellement selon les accords passés et/ou selon l'approbation écrite du client pour des coûts spécifiques. La rémunération et les indemnités de frais seront négociées annuellement entre les parties et sont soumises aux normes et les intervalles de l'indexation officielle des salaires.",
            h24: "4. Facturation",
            para4: "La rémunération de la mission est facturée mensuellement par DIVERKO au client. Toutes les factures sont payables dans un délai de 15 jours à compter de la date de facturation. Le Client s'engage à payer la facture à temps et en la transférant sur le compte de DIVERKO avec le n° IBAN BE07 7370 5993 8666 (BIC : KREDBEBB). DIVERKO a le droit de suspendre l'exécution du contrat en cas de non-paiement des factures.",
            h25: "5. Obligations légales",
            para5: "DIVERKO confirme se conformer à toutes les dispositions légales et réglementaires afin d'exécuter correctement sa mission. DIVERKO déclare se conformer aux conditions de la législation sociale et fiscale belge.",
            h26: "6. Transfert",
            para6: "Aucun des droits et obligations découlant du présent accord ne peut être cédé à un tiers, sauf avec le consentement exprès des autres parties.",
            h27: "7. Clause de non-concurrence",
            para7: "DIVERKO s'engage à ne pas exercer, directement ou indirectement, d'activités concurrentielles sur le marché du Client et à ne pas approcher et céder, directement ou indirectement, des clients du Client pendant la durée du présent contrat.",
            h28: "8. Confidentialité",
            para8: "Les parties considèrent comme des informations confidentielles toutes les informations qu'elles acquièrent sur l'autre partie pendant la formation et l'exécution du contrat. Les parties n'utiliseront les informations confidentielles que dans le cadre de l'exécution de l'accord. Les informations confidentielles ne doivent pas être divulguées ou mises à la disposition de tiers sans le consentement préalable de l'autre partie, sauf dans le cas où ces informations sont généralement connues et accessibles au public. DIVERKO déclare que cette confidentialité s'étend également à ses éventuels organes, agents d'exécution et personnel.",
            h29: "9. Droits intellectuels et droits de propriété",
            para9: "DIVERKO transfère automatiquement et de plein droit au Client tous les droits d'auteur ou autres droits intellectuels légalement transférables sur toutes les informations développées dans le cadre de la mission, sans que le Client ne doive une quelconque compensation supplémentaire. DIVERKO garantit le Client contre toute réclamation, fondée sur la violation ou la prétendue violation de tout droit de propriété intellectuelle du fait de l'utilisation des droits concédés. Le client doit immédiatement informer la DIVERKO de cette réclamation par écrit. Tous les documents utilisés et préparés par DIVERKO dans le cadre de la mission sont la propriété du client. DIVERKO remettra ces documents à la première demande du Client ou en tout cas à la fin du Contrat.",
            h210: "10. Responsabilité civile",
            para10: "DIVERKO est responsable envers le Client des dommages causés par sa faute ou sa négligence dans l'exécution de la mission, la violation des articles du Contrat ou la violation de toute disposition légale. DIVERKO confirme qu'elle a couvert sa responsabilité envers le client auprès d'un assureur responsabilité civile agréé pour une couverture minimale de 50.000 euros. Sur simple demande du Client, DIVERKO présente les pièces justificatives nécessaires.",
            h211: "11. Renonciation",
            para11: "La non revendication d'un droit ou la non application d'une sanction par l'une ou l'autre des parties n'implique en aucun cas une renonciation à ce droit. Une partie ne peut renoncer à ses droits que par écrit.",
            h212: "12. Nullité",
            para12: "La validité du contrat n'est pas affectée par la nullité d'une de ses clauses ; dans la mesure du possible, et sous réserve de l'accord mutuel des Parties, la clause déclarée nulle sera remplacée par une clause correspondant le plus possible à l'intention économique et à l'interprétation des Parties à l'Accord.",
            h213: "13. Résolution des litiges",
            para13: "Les parties s'engagent à résoudre tout litige relatif à l'accord, par l'intervention d'un médiateur. S'ils ne peuvent être résolus par les parties, les litiges seront soumis aux tribunaux de Louvain. Le droit belge est applicable à l'accord.",
        }
    }

    props.language === "English" ? (content = content.English) : (props.language === "Frans" ? (content = content.Frans) : (content = content.Nederlands));

    return(
        <div className="page">
        <Helmet>
            <title>Terms and Conditions | Diverko</title>
        </Helmet>
        <div className="py-32 h-full min-w-lg max-w-6xl m-auto px-2 300:px-8 text gap-8 flex flex-col">
            <h1>{content.title}</h1>
            <h2>{content.h21}</h2>
            <p>{content.para1}</p>
            <h2>{content.h22}</h2>
            <p>{content.para2}</p>
            <h2>{content.h23}</h2>
            <p>{content.para3}</p>
            <h2>{content.h24}</h2>
            <p>{content.para4}</p>
            <h2>{content.h25}</h2>
            <p>{content.para5}</p>
            <h2>{content.h26}</h2>
            <p>{content.para6}</p>
            <h2>{content.h27}</h2>
            <p>{content.para7}</p>
            <h2>{content.h28}</h2>
            <p>{content.para8}</p>
            <h2>{content.h29}</h2>
            <p>{content.para9}</p>
            <h2>{content.h210}</h2>
            <p>{content.para10}</p>
            <h2>{content.h211}</h2>
            <p>{content.para11}</p>
            <h2>{content.h212}</h2>
            <p>{content.para12}</p>
            <h2>{content.h213}</h2>
            <p>{content.para13}</p>
        </div>
    </div>
    )
}