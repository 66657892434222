import React from "react";
import { NavLink } from "react-router-dom";

function NavLinkComp(props) {
    return (
        <div className={props.css}>
            <NavLink className={({isActive})=> (isActive ? 'active' : 'link')}
                to={props.link}
                >
                <li className="p-3 font-semibold text-lg text-left border-b-2 border-transparent">
                    {props.name}
                </li>
            </NavLink>
        </div>
    );
}

export default NavLinkComp;